import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Style } from "../components/IndexPage/styled";
import Layout from "../components/Layout";

export default function IndexPage({ data: { heart }, location }) {
  return (
    <Layout location={location}>
      <Style>
        <GatsbyImage
          image={getImage(heart)}
          alt={"Eine grüne Hecke mit einem Loch in der Form eines Herzes"}
          className={"image"}
        />
        <h2 className={"spruch"}>
          Jeder Mensch braucht etwas Boden unter den Füßen, eine Hand voll Erde,
          um ein Pflänzchen wachsen zu lassen, und einen Baum, an den er sich
          lehnen kann. (Sabine Reber)
        </h2>
        <div className={"blur"} />
      </Style>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    heart: file(relativePath: { eq: "heart.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`;
