import styled from "styled-components";

export const Style = styled.div`
  position: relative;
  text-align: center;
  color: white;

  .image {
    max-height: calc(100vh - 95px);
  }

  .spruch {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    font-size: 2rem;
  }

  .blur {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;

    background-color: hsla(0, 0%, 0%, 64%);
  }

  @media (max-width: 700px) {
    .spruch {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 600px) {
    .spruch {
      margin-top: 1rem;
      position: relative;
      top: 0;
      left: 0;
      transform: translate(0%, 0%);
      color: black;
    }

    .blur {
      display: none;
    }
  }
`;
